<template>
    <section
        ref="element"
        class="flex scroll-mt-32 flex-col items-center"
    >
        <div class="flex justify-center">
            <hr class="h-16 w-1 border-none bg-gray-400">
        </div>

        <div class="w-full max-w-4xl rounded bg-gray-100 shadow-lg">
            <div v-if="paymentAmount" class="sticky top-16 z-10 px-2 pb-2 sm:px-4 sm:pb-4">
                <div class="-mx-px -mt-4 flex items-stretch justify-between rounded bg-white shadow-lg">
                    <div class="flex flex-col p-4 sm:flex-row sm:items-end sm:gap-2">
                        <div class="flex flex-col gap-1">
                            <AspectLabel>
                                {{ t('Payment of') }}
                            </AspectLabel>
                            <time class="text-2xl font-light sm:text-3xl lg:text-5xl">
                                {{ formatCurrency(paymentAmount.totalWithTransactionFee, paymentAmount.currency) }}
                            </time>
                        </div>
                        <div class="flex items-center gap-2 text-base sm:text-lg lg:text-xl">
                            <span>
                                {{ t('for') }}
                                <span class="font-normal">{{ tc(':amount item|:amount items', ticketsCount, { amount: ticketsCount.toString() }) }}</span>
                            </span>

                            <Tippy>
                                <AspectIcon class="mt-0.5 size-4 cursor-help text-gray-500" name="information-circle" />
                                <template #content>
                                    <AspectPricingTable
                                        :currency="paymentAmount.currency"
                                        :subtotal="paymentAmount.subtotal"
                                        :discount-amount="paymentAmount.discountAmount"
                                        :discounted-subtotal="paymentAmount.discountedSubtotal"
                                        :transaction-fee="paymentAmount.transactionFee"
                                        :total="paymentAmount.totalWithTransactionFee"
                                        :taxes="paymentAmount.taxes"
                                    />
                                </template>
                            </Tippy>
                        </div>
                    </div>

                    <AspectButtonAttached
                        class="rounded-r border-l border-gray-200"
                        color="black"
                        @click="emit('change')"
                    >
                        {{ t('Change') }}
                    </AspectButtonAttached>
                </div>
            </div>

            <div class="flex flex-col gap-4 p-2 lg:p-4">
                <!-- Informations -->
                <template v-if="member && features.active('project:member-area-events')">
                    <h3 class="text-center text-xl">
                        {{ t('Logged in as') }}
                    </h3>
                    <div class="grid grid-cols-6">
                        <article
                            :class="[
                                'relative isolate flex items-center',
                                'col-span-full md:col-start-2 md:col-end-6',
                            ]"
                        >
                            <figure
                                :class="[
                                    'z-10 shrink-0 rounded-full bg-white p-1.5 shadow-lg',
                                    'hidden sm:flex'
                                ]"
                            >
                                <img
                                    :class="[
                                        'rounded-full border border-gray-200 object-cover',
                                        'size-20 md:size-24'
                                    ]"
                                    :src="vapor.asset('/images/default-person.png')"
                                    alt="Profile Picture"
                                >
                            </figure>

                            <div class="-ml-12 flex h-21 grow justify-between gap-3 rounded-r bg-white pl-12 shadow-lg">
                                <div class="flex grow flex-col items-start justify-center p-3">
                                    <AspectLabel class="!text-3xs md:text-inherit">
                                        <AspectIcon
                                            class="size-3 text-yellow-800"
                                            name="crown"
                                        />
                                        {{ membershipName }} {{ t('Member') }}
                                    </AspectLabel>
                                    <h5 class="line-clamp-1 text-sm font-normal lg:text-lg">
                                        {{ member.name || '--' }}
                                    </h5>
                                    <div
                                        v-if="membership"
                                        class="flex items-center gap-1.5 text-gray-500"
                                    >
                                        <AspectIcon class="size-3" name="sign-hashtag" />
                                        <AspectData type="id" class="text-2xs">
                                            {{ getMemberNumber(member, membership) }}
                                        </AspectData>
                                    </div>
                                </div>

                                <AspectButtonAttached class="shrink-0 rounded-r border-l" @click="logout">
                                    <AspectIcon class="size-5" name="logout-1" />
                                </AspectButtonAttached>
                            </div>
                        </article>
                    </div>
                </template>

                <ReservationInformationForm v-else v-model="form" />

                <!-- Payment -->
                <ReservationPaymentForm
                    v-model:form="form"
                    v-model:promo-code-form="promoCodeForm"
                    v-model:has-error="hasError"
                    :is-free="isFree"
                    :payment-amount="paymentAmount"
                    :terms="termsAndConditions"
                    :offerings-selected="offeringsSelected"
                    :payment-methods="paymentMethods"
                    :promo-ticket-limit="promoTicketLimit"
                    :entries="entries"
                    @submit="value => emit('submit', value)"
                    @apply-promo-code="applyPromoCode"
                />
            </div>

            <div class="flex lg:sticky lg:inset-x-0 lg:bottom-0 lg:z-10">
                <AspectButtonAttached
                    :disabled="!offeringsSelected || !allEntriesValid || hasError"
                    :disabled-reason="disabledReason"
                    class="grow rounded-b border-t border-gray-200"
                    color="black"
                    size="xl"
                    :color-hover-only="false"
                    :loading="form.processing"
                    @click="onSubmit"
                >
                    {{ form.payment.method === 'card' ? t('Reserve & Pay') : t('Reserve') }}
                </AspectButtonAttached>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { camelCase } from 'lodash-es';
    import { Tippy } from 'vue-tippy';

    import { getTranslatedValue, t, tc } from '@aspect/shared/plugins/i18n.ts';
    import { formatCurrency } from '@aspect/shared/utils/number.ts';
    import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';
    import { useLaravelVapor } from '@aspect/shared/composables/use-laravel-vapor.ts';

    import { useRoute } from '@/shared/composables/use-route.ts';
    import { usePageProps } from '@/shared/composables/use-page-props.ts';
    import { useFeatures } from '@/shared/composables/use-features.ts';
    import { useMemberships } from '@/shared/composables/use-memberships.ts';

    import { useSquare } from '@/consumer/composables/use-square.ts';


    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';
    import AspectPricingTable from '@aspect/shared/components/aspect-pricing-table.vue';

    import ReservationPaymentForm from '@/consumer/components/reservation/reservation-payment-form.vue';
    import ReservationInformationForm from '@/consumer/components/reservation/reservation-information-form.vue';

    import type { InertiaForm } from '@inertiajs/vue3';
    import type {
        CreateEntryData,
        CreatePaymentData,
        CreateReservationData,
        DivisionData,
        PaymentMethodsData,
        TranslatableData,
        PromotionUsageData,
        ConsumerPromotionValidateRequest,
        AmountData, PaymentMethod, PaymentCardBrand,
    } from '@aspect/shared/types/generated';


    const props = withDefaults(defineProps<{
        division: DivisionData;
        entries: CreateEntryData[];
        termsAndConditions: TranslatableData;
        paymentMethods: PaymentMethodsData;
        paymentAmount: AmountData | null;
        offeringsSelected: boolean;
        allEntriesValid: boolean;
        ticketsCount: number;
        initialPromoCode? : string | null;
    }>(), {
        initialPromoCode: null,
    });

    const emit = defineEmits<{
        submit: [value: CreatePaymentData],
        change: [],
    }>();

    const features = useFeatures();
    const { consumerRoute, ticketOfficeRoute } = useRoute();
    const { getMemberNumber } = useMemberships();

    const form = defineModel<InertiaForm<CreateReservationData>>('form', { required: true });


    // MEMBER
    const vapor = useLaravelVapor();
    const pageProps = usePageProps();
    const member = computed(() => pageProps.value.member);

    const membership = computed(() => {
        if (!member.value || !member.value.memberships.length) {
            return null;
        }

        return member.value.memberships[0];
    });

    const membershipName = computed(() => {
        if (!membership.value) {
            return '';
        }

        return getTranslatedValue(membership.value.name);
    });


    // LOGOUT
    async function logout() {
        const logoutForm = useAxiosForm();
        const response = await logoutForm.post(ticketOfficeRoute('/logout'), {
            guard: 'member',
            redirect: false,
        }, false);

        if (response) {
            router.reload({
                only: ['member'],
            });
        }
    }


    // PROMO CODES
    const promoCodeForm = useForm<ConsumerPromotionValidateRequest>({
        entries: [],
        codes: [],
        attemptedCode: props.initialPromoCode
    });

    const promoTicketLimit = reactive<{
        value: number | null;
        reached: boolean;
    }>({
        value: null,
        reached: false,
    });

    function onValidPromoCode(response: { data: PromotionUsageData }) {
        if (!response) {
            return;
        }

        const data = response.data as PromotionUsageData;

        promoTicketLimit.value = data.ticketLimit;
        promoTicketLimit.reached = !!data.ticketLimit && data.ticketUsed > data.ticketLimit;
    }

    async function applyPromoCode() {
        if (!promoCodeForm.attemptedCode) {
            promoCodeForm.clearErrors();
            return;
        }

        if (form.value.promoCodes.includes(promoCodeForm.attemptedCode)) {
            promoCodeForm.attemptedCode = null;
            return;
        }

        const code = promoCodeForm.attemptedCode.toUpperCase();

        const response = await useAxiosForm(promoCodeForm).post(consumerRoute('/{division}/promotions/validate', {
            division: props.division.id,
        }), {
            codes: form.value.promoCodes,
            entries: props.entries,
            attemptedCode: code,
        }, false);

        if (response) {
            onValidPromoCode(response);

            form.value.clearErrors('promoCodes');
            form.value.promoCodes = [...form.value.promoCodes, code];

            promoCodeForm.attemptedCode = null;
        }
    }

    onMounted(() => {
        if (props.initialPromoCode) {
            applyPromoCode();
        }
    });

    async function validatePromoCodes() {
        if (!form.value.promoCodes.length || !props.entries.length) {
            return;
        }

        const payload: ConsumerPromotionValidateRequest = {
            codes: form.value.promoCodes,
            entries: props.entries,
        };

        const response = await useAxiosForm().post(consumerRoute('/{division}/promotions/validate', {
            division: props.division.id,
        }), payload, false);

        if (response) {
            onValidPromoCode(response);
        }
    }


    watch(() => props.entries, () => {
        validatePromoCodes();
    });

    onMounted(() => {
        validatePromoCodes();
    });


    // SCROLL
    const element = ref<HTMLElement>();

    onMounted(() => {
        element.value?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    });


    // CAN RESERVE
    const hasError = ref(false);


    // DISABLED REASON
    const disabledReason = computed(() => {
        if (!props.offeringsSelected) {
            return t('You must select at least one ticket');
        }

        if (!props.allEntriesValid) {
            return t('Fill in ticket information above');
        }

        return '';
    });


    // IS FREE
    const isFree = computed(() => {
        if (!props.paymentAmount) {
            return false;
        }

        return props.paymentAmount.total === 0;
    });


    // ON SUBMIT
    const { tokenizeCard } = useSquare();

    async function onSubmit() {
        const getMethod = () => {
            if (form.value.payment.bypass) {
                return null;
            }

            if (isFree.value) {
                // It doesn't matter what the payment method is if it's free
                return 'onSite';
            }

            return form.value.payment.method;
        };

        const payment: CreatePaymentData = {
            provider: null,
            method: getMethod(),
            intentId: null,
            card: null,
            currency: 'CAD',
            bypass: form.value.payment.bypass,
        };

        if (payment.method === 'card') {
            const tokenResult = await tokenizeCard();

            if (tokenResult.status !== 'OK') {
                form.value.processing = false;
                return;
            }

            payment.provider = 'square';
            payment.method = camelCase(tokenResult.details?.method) as PaymentMethod;
            payment.intentId = tokenResult.token as string;
            payment.card = {
                brand: camelCase(tokenResult.details?.card?.brand) as PaymentCardBrand,
                lastFour: tokenResult.details?.card?.last4 as string,
                expirationMonth: tokenResult.details?.card?.expMonth as number,
                expirationYear: tokenResult.details?.card?.expYear as number,
            };
        }

        emit('submit', payment);
    }

    // ON MOUNTED
    onMounted(() => {
        const firstEntry = props.entries[0];

        if (!firstEntry || !form.value.customer) {
            return;
        }

        if (firstEntry.firstName && !form.value.customer.firstName) {
            form.value.customer.firstName = firstEntry.firstName;
        }

        if (firstEntry.lastName && !form.value.customer.lastName) {
            form.value.customer.lastName = firstEntry.lastName;
        }
    });
</script>
